import React from 'react';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { useAppSelector } from '../../store/hooks';
import usePermissions from '../../lib/utils/usePermissions';
import { datePikerMaskDefault } from '../../lib/const/masks';
import { Col, DatePicker, Divider, Form, Modal, Row, Select } from 'antd';
import { displayFormat, toDisplayFormat } from '../../lib/utils/formatDate';
import { ReactComponent as AgreementSigned } from '../../lib/img/icon/agreement/agreement_signed.svg';
import { ReactComponent as AgreementNotSigned } from '../../lib/img/icon/agreement/agreement_not_signed.svg';
import { ReactComponent as ArrowDownFigIcon } from '../../lib/img/arrow-down-fig.svg';

interface Props {
  isNewUser?: boolean;
  isEditMode: boolean;
}

function AgreementBlock(props: Props) {
  const { isNewUser, isEditMode } = props;
  const { personFullCard } = useAppSelector((state) => state.persons);
  const { contractStatuses } = useAppSelector((state) => state.dictionaries);
  const [hasAccess] = usePermissions();

  return (
    <div className="block">
      <Row justify="space-between">
        <Col>
          <h3>Договор</h3>
        </Col>
      </Row>

      <div style={{ width: '100%' }}>
        <Divider />
      </div>

      <Row gutter={50} style={{ minHeight: 58 }}>
        <Col>
          <Row>Дата окончания:</Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item dependencies={['statusId']}>
                {({ getFieldValue }) => {
                  const statusId = getFieldValue('statusId');
                  return (
                    <Form.Item
                      name="endDate"
                      rules={[
                        {
                          required: statusId === 2,
                          message: '',
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: 150 }}
                        format={displayFormat}
                        inputRender={(props) => {
                          return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                        }}
                        disabled={!hasAccess(['PERSON.CONTRACT.WRITE'])}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            ) : (
              <div className="info">
                {personFullCard?.contract?.endDate
                  ? `${toDisplayFormat(moment(personFullCard?.contract?.endDate))}`
                  : '-'}
              </div>
            )}
          </Row>
        </Col>
        <Col>
          <Row>Статус договора:</Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item shouldUpdate>
                {({ validateFields }) => {
                  return (
                    <Form.Item
                      name="statusId"
                      rules={[
                        {
                          required: true,
                          message: '',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Выберите статус"
                        style={{ width: 132 }}
                        onChange={() => {
                          validateFields(['endDate']);
                        }}
                        disabled={!hasAccess(['PERSON.CONTRACT.WRITE'])}
                        suffixIcon={<ArrowDownFigIcon />}
                      >
                        {contractStatuses?.map(({ name, id }) => (
                          <Select.Option value={id}>{name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            ) : (
              <div className="info">
                {personFullCard?.contract?.statusId
                  ? `${contractStatuses.find((type) => type.id === personFullCard?.contract?.statusId)?.name}`
                  : '-'}
              </div>
            )}
          </Row>
        </Col>

        <Col>
          <Row className="mt5">
            {isEditMode || isNewUser ? (
              <Form.Item dependencies={['statusId']}>
                {({ getFieldValue }) => {
                  const statusId = getFieldValue('statusId');
                  return (
                    <Form.Item name="statusIdIco">
                      {statusId === 2 ? <AgreementSigned /> : <AgreementNotSigned />}
                    </Form.Item>
                  );
                }}
              </Form.Item>
            ) : (
              <div className="info">
                {personFullCard?.contract?.statusId === 2 ? <AgreementSigned /> : <AgreementNotSigned />}
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default AgreementBlock;
