import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Select, Checkbox } from 'antd';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import FilesCollapse from '../FilesCollapse/FilesCollapse';
import { useNavigate } from 'react-router-dom';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { ReactComponent as InPrayListIcon } from '../../lib/img/in-pray-list-icon.svg';
import { toDisplayFormat } from '../../lib/utils/formatDate';
import { setFilesPage, setFilesInPrayListFilter } from '../../store/slices/rootSlice/rootSlice';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import {
  PERSONS_REPORT,
  PERSONS_REPORT_ALLREGIONS,
  PERSON_CREATE,
  PERSON_READ,
  PERSON_READ_ALLREGIONS,
  PERSON_READ_FULLACCESS,
} from '../../lib/const/permissions';
import { ReactComponent as Papers } from '../../lib/img/papers.svg';
import { FilesReportModal } from './FilesReportModal';
import { ReactComponent as AgreementSigned } from '../../lib/img/icon/agreement/agreement_signed.svg';
import { ReactComponent as AgreementNotSigned } from '../../lib/img/icon/agreement/agreement_not_signed.svg';

interface Props {
  setSelectedTypesId: (id: number | undefined) => void;
}

function Files(props: Props) {
  const { setSelectedTypesId } = props;
  const dispatch = useAppDispatch();
  const { filesPage, filesInPrayListFilter, selectedTypesId } = useAppSelector((state) => state.root);
  const { persons, personsLoading, totalPersons } = useAppSelector((state) => state.persons);
  const [tableData, setTableData] = useState<any>([]);
  const { subwayStation, personTypes, personStatuses } = useAppSelector((state) => state.dictionaries);
  const navigate = useNavigate();

  const [reportModalVisible, setReportModalVisible] = useState<boolean>(false);

  const { hasAccess } = useMainContext();

  const shouldArchiveAgreementRender = () => {
    if (hasAccess([PERSON_READ_FULLACCESS])) return true;
    return false;
  };

  const isAgreementView = shouldArchiveAgreementRender();

  useEffect(() => {
    if (checkIsLoaded(personsLoading)) {
      setTableData(
        persons.map((person) => {
          return {
            key: person?.id,
            id: person?.id,
            createDate: person.createDate,
            firstName: person.firstName,
            middleName: person.middleName,
            email: person.email,
            lastName: person.lastName,
            subway: person.address?.subwayStationId,
            unstructuredAddress: person.address?.unstructuredAddress,
            inPrayList: person.inPrayList,
            personStatusId: person.personStatusId,
            personTypeId: person.personTypeId,
            phones: person.phones && person.phones.map(({ phone }) => phone),
            agreementStatusId: person.contract?.statusId,
          };
        })
      );
    }
  }, [personsLoading, persons]);

  const columns = [
    {
      title: 'Дата создания',
      dataIndex: 'createDate',
      key: 'createDate',
      width: '8%',
      render: (value) => toDisplayFormat(value),
    },
    {
      title: 'Тип',
      dataIndex: 'personTypeId',
      key: 'personTypeId',
      width: '8%',
      render: (value) => {
        return personTypes.find((type) => type.id === value)?.name;
      },
    },
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: 'name',
      width: '12%',
      render: (value, record) => `${record.lastName} ${record.firstName} ${record.middleName}`.replace('undefined', ''),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '12%',
    },
    {
      title: 'Телефон',
      dataIndex: 'phones',
      key: 'phones',
      width: '12%',
      render: (value) => value[0],
    },
    {
      title: 'Метро',
      dataIndex: 'subway',
      key: 'subway',
      width: '12%',
      render: (value) => {
        return subwayStation.find((subway) => subway.id === value)?.name;
      },
    },
    {
      title: 'Адрес',
      dataIndex: 'unstructuredAddress',
      key: 'unstructuredAddress',
      width: '28%',
    },
    {
      title: 'В списке для молитв',
      dataIndex: 'inPrayList',
      key: 'inPrayList',
      width: '12%',
      render: (value) => {
        return value ? <InPrayListIcon /> : null;
      },
    },
    {
      title: 'Статус',
      dataIndex: 'personStatusId',
      key: 'personStatusId',
      width: '12%',
      render: (value) => {
        return personStatuses.find((status) => status.id === value)?.name;
      },
    },
    {
      title: 'Договор',
      dataIndex: 'agreementStatusId',
      key: 'agreementStatusId',
      width: '12%',
      isHiddenRender: !isAgreementView,
      render: (value) => {
        return (
          <div className="mt10">
            {value === 2 ? <AgreementSigned width={24} height={24} /> : <AgreementNotSigned width={24} height={24} />}
          </div>
        );
      },
    },
  ].filter((el) => !el.isHiddenRender);

  return (
    <div className="pt20 container">
      <Row justify="space-between" className="mb20 pr10 pl10">
        <Col>
          <Select
            style={{ minWidth: '216px', textAlign: 'left' }}
            placeholder="Наименование типа"
            allowClear
            value={selectedTypesId || null}
            onChange={(value) => setSelectedTypesId(Number(value))}
          >
            {personTypes.map((type) => {
              return (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              );
            })}
          </Select>
          <Checkbox
            className="pl24"
            checked={filesInPrayListFilter}
            onChange={(e) => dispatch(setFilesInPrayListFilter(e.target.checked))}
          >
            В списке для молитв
          </Checkbox>
        </Col>
        <Col className="display-flex">
          {hasAccess([PERSON_CREATE]) && (
            <Button className="mr20" onClick={() => navigate('../new-ward', { replace: true })}>
              Добавить анкету
            </Button>
          )}
          {hasAccess([PERSONS_REPORT, PERSONS_REPORT_ALLREGIONS]) && (
            <Button
              style={{ width: 216 }}
              className="display-flex"
              onClick={() => {
                setReportModalVisible(true);
              }}
            >
              <div className="ml-auto mr-auto display-flex">
                <Papers className="mr7 mt1" />
                Сформировать отчет
              </div>
            </Button>
          )}
        </Col>
      </Row>
      <FilesCollapse />
      <Table
        columns={columns}
        dataSource={tableData}
        loading={checkIsLoading(personsLoading)}
        scroll={{
          y: '60vh',
        }}
        pagination={{
          position: ['bottomCenter'],
          current: filesPage,
          total: totalPersons,
          pageSize: 10,
          showSizeChanger: false,
          onChange: (page) => dispatch(setFilesPage(page)),
        }}
        onRow={({ id }) => ({
          onClick: () => navigate(`../${id}/general`, { replace: true }),
        })}
      />
      <FilesReportModal
        closeModal={() => {
          setReportModalVisible(false);
        }}
        visible={reportModalVisible}
      />
    </div>
  );
}

export default Files;
