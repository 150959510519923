import React from 'react';
import { Col, Divider, Form, Row, Select } from 'antd';
import { useAppSelector } from '../../store/hooks';
import { PersonTypes } from './PersonTypes';

interface Props {
  handleArchive?: () => void;
  isNewUser?: boolean;
  isEditMode: boolean;
}

function GeneralBlock(props: Props) {
  const { isNewUser, isEditMode } = props;
  const { personFullCard } = useAppSelector((state) => state.persons);
  const { personStatuses, personTypes } = useAppSelector((state) => state.dictionaries);
  const { projects } = useAppSelector((state) => state.projects);

  const projectsTitles = projects
    .flatMap((p) => {
      if (personFullCard?.projectIds && personFullCard.projectIds.includes(p.id)) return p.title;
      return [];
    })
    .filter((item) => item !== undefined)
    .join(', ');

  return (
    <div className="block">
      <Row justify="space-between">
        <Col>
          <h3>Общее</h3>
        </Col>
      </Row>
      <div style={{ width: '100%' }}>
        <Divider />
      </div>
      <Row gutter={50} style={{ minHeight: 58 }}>
        <Col>
          <Row>Тип:</Row>
          <Row>
            {isNewUser ? 'Подопечный' : personTypes.find((type) => type.id === personFullCard?.personTypeId)?.name}
          </Row>
        </Col>
        {personFullCard?.personTypeId !== PersonTypes.Подопечный && !isNewUser && (
          <Col>
            <Row>Статус:</Row>
            <Row>
              {isEditMode || isNewUser ? (
                <Form.Item
                  name="personStatusId"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select style={{ width: 160 }}>
                    {personStatuses
                      .filter((item) => item.name !== 'Архив' || !item.deleted)
                      .map((status) => (
                        <Select.Option key={status.id} value={status.id}>
                          {status.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              ) : (
                <>{personStatuses.find((status) => status.id === personFullCard?.personStatusId)?.name}</>
              )}
            </Row>
          </Col>
        )}
        {projectsTitles.length > 0 &&
          personFullCard?.personTypeId !== PersonTypes.Подопечный &&
          personFullCard?.personTypeId !== PersonTypes.Доброволец && (
            <Col>
              <Row>Входит в проекты:</Row>
              <Row>{projectsTitles}</Row>
            </Col>
          )}
      </Row>
    </div>
  );
}

export default GeneralBlock;
