export const APPLICATION_CREATE = 'APPLICATION.CREATE';
export const APPLICATION_FILES_DELETE = 'APPLICATION.FILES.DELETE';
export const APPLICATION_FILES_DOWNLOAD = 'APPLICATION.FILES.DOWNLOAD';
export const APPLICATION_FILES_READ = 'APPLICATION.FILES.READ';
export const APPLICATION_FILES_UPLOAD = 'APPLICATION.FILES.UPLOAD';
export const APPLICATION_READ_ALLCOMMENTS = 'APPLICATION.READ.ALLCOMMENTS';
export const APPLICATION_READ_EXECUTORS = 'APPLICATION.READ.EXECUTORS';
export const APPLICATION_READ_HISTORY = 'APPLICATION.READ.HISTORY';
export const APPLICATION_READ_RESPONSEHISTORY = 'APPLICATION.READ.RESPONSEHISTORY';
export const APPLICATION_READ_MYCOMMENTS = 'APPLICATION.READ.MYCOMMENTS';
export const APPLICATION_READ_TECHINFO = 'APPLICATION.READ.TECHINFO';
export const APPLICATION_RESPOND = 'APPLICATION.RESPOND';
export const APPLICATION_STATUSCHANGE = 'APPLICATION.STATUSCHANGE';
export const APPLICATION_STATUSCHANGE_ALLREGIONS = 'APPLICATION.STATUSCHANGE.ALLREGIONS';
export const APPLICATION_STATUSCHANGE_MYREGION = 'APPLICATION.STATUSCHANGE.MYREGION';
export const APPLICATION_WRITE = 'APPLICATION.WRITE';
export const APPLICATION_WRITE_ALLREGIONS = 'APPLICATION.WRITE.ALLREGIONS';
export const APPLICATION_WRITE_MYREGION = 'APPLICATION.WRITE.MYREGION';
export const APPLICATIONS_READ = 'APPLICATIONS.READ';
export const APPLICATIONS_READ_ALLREGIONS = 'APPLICATIONS.READ.ALLREGIONS';
export const APPLICATIONS_READ_FULLACCESS = 'APPLICATIONS.READ.FULLACCESS';
export const APPLICATIONS_REPORT = 'APPLICATIONS.REPORT';
export const APPLICATIONS_REPORT_ALLREGIONS = 'APPLICATIONS.REPORT.ALLREGIONS';
export const PERSON_CREATE = 'PERSON.CREATE';
export const PERSON_FILES_DELETE = 'PERSON.FILES.DELETE';
export const PERSON_FILES_DOWNLOAD = 'PERSON.FILES.DOWNLOAD';
export const PERSON_FILES_READ = 'PERSON.FILES.READ';
export const PERSON_FILES_UPLOAD = 'PERSON.FILES.UPLOAD';
export const PERSON_PRAYLIST_WRITE = 'PERSON.PRAYLIST.WRITE';
export const PERSON_READ = 'PERSON.READ';
export const PERSON_READ_ALLCOMMENTS = 'PERSON.READ.ALLCOMMENTS';
export const PERSON_READ_ALLREGIONS = 'PERSON.READ.ALLREGIONS';
export const PERSON_READ_FULLACCESS = 'PERSON.READ.FULLACCESS';
export const PERSON_READ_HISTORY = 'PERSON.READ.HISTORY';
export const PERSON_READ_MYCOMMENTS = 'PERSON.READ.MYCOMMENTS';
export const PERSON_READ_PROJECTS = 'PERSON.READ.PROJECTS';
export const PERSON_STATUS_WRITE = 'PERSON.STATUS.WRITE';
export const PERSON_WRITE = 'PERSON.WRITE';
export const PERSON_WRITE_ALLREGIONS = 'PERSON.WRITE.ALLREGIONS';
export const PERSONLIST_READ = 'PERSONLIST.READ';
export const PERSONLIST_READ_ALLREGIONS = 'PERSONLIST.READ.ALLREGIONS';
export const PROFILE_WRITE = 'PROFILE.WRITE';
export const PROFILE_SUBSCRIPTION = 'PROFILE.SUBSCRIPTION';
// export const PERSONLIST_READ_ALLREGIONS = "PERSONLIST.READ.ALLREGIONS";
export const ADMINISTRATION_READ = 'ADMINISTRATION.READ';
export const ADMINISTRATION_ADDUSER = 'ADMINISTRATION.ADDUSER';
export const ADMINISTRATION_USER_WRITE = 'ADMINISTRATION.USER.WRITE';
export const ADMINISTRATION_PROJECT_WRITE = 'ADMINISTRATION.PROJECT.WRITE';
export const ADMINISTRATION_ADDPROJECT = 'ADMINISTRATION.ADDPROJECT';
export const APPLICATION_COPY_MYREGION = 'APPLICATION.COPY.MYREGION';
export const APPLICATION_COPY_ALLREGIONS = 'APPLICATION.COPY.ALLREGIONS';
export const ADMINISTRATION_ADDROLE = 'ADMINISTRATION.ADDROLE';
export const ADMINISTRATION_ROLE_WRITE = 'ADMINISTRATION.ROLE.WRITE';
export const ADMINISTRATION_DIC_WRITE = 'ADMINISTRATION.DIC.WRITE';
export const ADMINISTRATION_ADDTERM = 'ADMINISTRATION.ADDTERM';
export const INFO_FILES_READ = 'INFO.FILES.READ';
export const INFO_FILES_UPLOAD = 'INFO.FILES.UPLOAD';
export const INFO_FILES_DELETE = 'INFO.FILES.DELETE';
export const INFO_FILES_DOWNLOAD = 'INFO.FILES.DOWNLOAD';
export const INFO_ADDSECTION = 'INFO.ADDSECTION';
export const INFO_WRITE = 'INFO.WRITE';
export const INFO_DELETE = 'INFO.DELETE';
export const INFO_READ_OPENED = 'INFO.READ.OPENED';
export const INFO_READ_CLOSED = 'INFO.READ.CLOSED';
export const APPLICATIONS_INDICATOR = 'APPLICATIONS.INDICATOR';
export const PERSONS_REPORT = 'PERSONS.REPORT';
export const PERSONS_REPORT_ALLREGIONS = 'PERSONS.REPORT.ALLREGIONS';
export const PERSON_CONTRACT_WRITE = 'PERSON.CONTRACT.WRITE';
